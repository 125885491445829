import React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {GOOGLE_RECAPTCHA_KEY} from 'helper'
import { NarrowContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import ChargeWithUsFormContent from './chargeWithUsFormContent';

function ChargeWithUsForm(props) {
    return (
        <div className='center-align'>
        <NarrowContainer className='center-align'>
            <Grid item md={9} xs={12} sx={{textAlign:'center'}} pb={4}>
                <p>Considering using STELLS power rover to get charged in your next mission? </p>
                <p>Fill out the form below and someone from our team will get back to you within 48 business hours.</p>
            </Grid>
            <Grid item md={12} xs={12} sx={{
                background:'rgba(255,255,255,0.1)',
                borderRadius:"15px"}}>
            <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                <ChargeWithUsFormContent/>
            </GoogleReCaptchaProvider>
            </Grid>
        </NarrowContainer>
        </div>
    );
}

export default ChargeWithUsForm;