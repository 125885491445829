import React from 'react';
import CenterTitle from './centerTitle';
import { WideContainer } from './PxftContainer';

const BasicHeader = ({title}) => {
    const backgroundCss = {
        backgroundImage: `url('/images/header-background.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
        height:'320px',
        margin:'0 0 50px 0'
    }
    return (
        <WideContainer>
            <div className='center-align' style={backgroundCss}>
                <CenterTitle title={title}/>
            </div>
        </WideContainer>
    );
}

export default BasicHeader;