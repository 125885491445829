import React from 'react';
import ContactFormContent from './contactFormContent';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {GOOGLE_RECAPTCHA_KEY} from 'helper'
import { NarrowContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';

const ContactForm =()=> {
    return (
        <div className='center-align'>
        <NarrowContainer className='center-align'>
            <Grid item md={9} xs={12} sx={{
                background:'rgba(255,255,255,0.1)',
                borderRadius:"15px"}}>
            <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                <ContactFormContent/>
            </GoogleReCaptchaProvider>
            </Grid>
        </NarrowContainer>
        </div>
    );
}

export default ContactForm;