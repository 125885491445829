import { React,useState,useEffect} from 'react';
import axios from 'axios.js';
import { Grid,TextField,Button, Snackbar, Alert,Backdrop,CircularProgress} from '@mui/material';
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const initialFormValues = {
    email_type: 'spaceContact',
    name: "",
    email: "",
    phone:"",
    subject: "",
    message: "",
  };

const initialAlertValues ={
        alertOpen: false,
        alertType: "success",
        alertMessage: ""
    }

const ContactFormContent =()=> {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formValues, setFormValues] = useState(initialFormValues);
    const [alertValues, setAlertValues ] = useState(initialAlertValues);
    const [errors, setErrors] = useState({});
    const [onchangeItem,setOnChangeItem]=useState({});
    const [loading, setLoading] = useState(false)
    const [loadingTimeOut,setLoadingTimeOut] = useState(false)

    useEffect(()=>{
        const validate = (fieldValues = formValues) => {
            let temp = {...errors}
            if ('name' in fieldValues){
                if(formValues.name.length>250){
                    temp.name="The name must be less than 250 characters"
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.name)){
                    temp.name="The name must be only letters.";
                }
                else temp.name='';
            }
            if ('email' in fieldValues){
                if(formValues.email.length===0){
                    temp.email=''
                }else if(formValues.email.length<5 || formValues.email.length>250){
                    temp.email="The email must be more than 5 and less than 250 characters"
                }else if(!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)){
                    temp.email="Email is not valid.";
                }
                else temp.email='';
            }
            if ('phone' in fieldValues){
                if(formValues.phone.length===0){
                    temp.phone=''
                }
                else if(!/^\(?(([+][0-9])?[0-9]?[0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(fieldValues.phone)){
                    temp.phone="The phone number is not valid.";
                }else temp.phone='';
            }
            if ('subject' in fieldValues){
                if(formValues.subject.length>250){
                    temp.subject="The subject must be less than 250 characters"
                }else temp.subject='';
            }
            if ('message' in fieldValues){
                if(formValues.message.length===0){
                    temp.message=''}
                else if(formValues.message.length<5 || formValues.message.length>250){
                    temp.message="The message must be more than 5 and less than 250 characters"
                }else temp.message='';
            }
            setErrors({
                ...temp
            });
        }
        validate(onchangeItem)
    },[onchangeItem,formValues])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertValues(initialAlertValues)
    };

    const formIsValid = (fieldValues = formValues) => {
        const isValid =
          fieldValues.name &&
          fieldValues.email &&
          fieldValues.subject &&
          fieldValues.message &&
          Object.values(errors).every((x) => x === "");
        return isValid;
      };

    const sendEmailAsync = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('submit_space_contact_form');
            const updatedFormValue = {...formValues,"g-recaptcha-response":token}
            const status = await axios.post("/email/send",updatedFormValue)
            .then((res) => {
                return (res.data && res.data.status != null) ? res.data : {status: false}
            })
            .catch((error)=> {
                return {status: false}
            });
            return status;
        }else{
            setLoading(true)
        }
    }
    useEffect(()=>{
        if(executeRecaptcha){
            setLoading(false)
        }
    },[executeRecaptcha])

    useEffect(()=>{
        if(loading){
            setTimeout(() => {
                setLoadingTimeOut(true)
            }, 30000);
        }else{
            setLoadingTimeOut(false)
        }
    },[loading])

    useEffect(()=>{
        if(loadingTimeOut && loading){
            setAlertValues({
                alertOpen: true,
                alertType: "warning",
                alertMessage: "Request expired. Please refresh the page."
            })
        }
    },[loadingTimeOut,loading])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formIsValid()){
            setLoading(true)
            let info = await sendEmailAsync();
            if(info){
                setLoading(false)
            setAlertValues({
                alertOpen: true,
                alertType: (info.status) ? "success" : "warning",
                alertMessage: info.msg || "Something went wrong."
            })
            event.target.reset();
            setFormValues(initialFormValues)
            }
        }else{
            console.log('errors',errors)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOnChangeItem({ [name]: value })
        setFormValues({
          ...formValues,
          [name]: value,
        });
      };

    return (
            <form onSubmit={handleSubmit} style={{maxWidth:'900px'}}>
            <Grid container sx={{p:2}} alignItems="center" justify="center">
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="name-input"
                    name="name"
                    label="Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.firstname}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["name"] && { error: true, helperText: errors["name"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="email-input"
                    name="email"
                    label="Email"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.email}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["email"] && { error: true, helperText: errors["email"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField  id="phone-input"
                    name="phone"
                    label="Phone"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.phone}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["phone"] && { error: true, helperText: errors["phone"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="subject-input"
                    name="subject"
                    label="Subject"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.website}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["subject"] && { error: true, helperText: errors["subject"] })}/>
                </Grid>
                <Grid item xs={12} className='center-align'>
                <TextField id="message-input"
                    name="message"
                    label="Message"
                    type="text"
                    multiline rows={4}
                    sx={{ m: 1, width: '95%',}}
                    value={formValues.message}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["message"] && { error: true, helperText: errors["message"] })}/>
                </Grid>
                <Grid item xs={12} className='center-align'>
                <Button sx={{ m: 1, width: '95%' }} variant="contained" color="primary" type="submit">
                    Submit
                </Button>
                </Grid>
                <Snackbar open={alertValues.alertOpen} autoHideDuration={20000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertValues.alertType} sx={{ width: '100%' }}>
                    {alertValues.alertMessage}
                </Alert>
                </Snackbar>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: '99'}} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </form>
    );
}

export default ContactFormContent;