import { React,useState,useEffect} from 'react';
import axios from 'axios.js';
import { Grid,TextField,Button, Snackbar, Alert,Backdrop,CircularProgress,InputAdornment} from '@mui/material';
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const initialFormValues = {
    email_type: 'spaceCharge',
    name: "",
    email: "",
    company:"",
    payload: "",
    battery: "",
    charge_time:"",
    launch_date: "",
    land_date: "",
    location:"",
    lander_provider: "",
    questions: "",
  };

const initialAlertValues ={
        alertOpen: false,
        alertType: "success",
        alertMessage: ""
    }

const ChargeWithUsFormContent =()=> {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formValues, setFormValues] = useState(initialFormValues);
    const [alertValues, setAlertValues ] = useState(initialAlertValues);
    const [errors, setErrors] = useState({});
    const [onchangeItem,setOnChangeItem]=useState({});
    const [loading, setLoading] = useState(false)
    const [loadingTimeOut,setLoadingTimeOut] = useState(false)

    useEffect(()=>{
        const validate = (fieldValues = formValues) => {
            let temp = {...errors}
            if ('name' in fieldValues){
                if(formValues.name.length===0){
                    temp.name=''
                }else if(formValues.name.length>250){
                    temp.name="The name must be less than 250 characters"
                }else if(!/^[a-zA-Z\s]*$/.test(fieldValues.name)){
                    temp.name="The name must be only letters.";
                }
                else temp.name='';
            }
            if ('email' in fieldValues){
                if(formValues.email.length===0){
                    temp.email=''
                }else if(formValues.email.length<5 || formValues.email.length>250){
                    temp.email="The email must be more than 5 and less than 250 characters"
                }else if(!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)){
                    temp.email="Email is not valid.";
                }
                else temp.email='';
            }
            if ('campany' in fieldValues){
                if(formValues.campany.length===0){
                    temp.campany=''
                }else if(formValues.campany.length<5 || formValues.campany.length>250){
                    temp.email="The campany must be more than 5 and less than 250 characters"
                }else temp.campany='';
            }
            if ('payload' in fieldValues){
                if(formValues.payload.length===0){
                    temp.payload=''
                }else if(!/^\d*\.{0,1}\d+$/.test(fieldValues.payload)){
                    temp.payload="The number is not valid.";
                }else if(formValues.payload.length>250){
                    temp.payload="The number is too large"
                }else temp.payload='';
            }
            if ('battery' in fieldValues){
                if(formValues.battery.length===0){
                    temp.battery=''
                }else if(!/^\d*\.{0,1}\d+$/.test(fieldValues.battery)){
                    temp.battery="The number is not valid.";
                }else if(formValues.battery.length>250){
                    temp.battery="The number is too large"
                }else temp.battery='';
            }
            if ('charge_time' in fieldValues){
                if(formValues.charge_time.length===0){
                    temp.charge_time=''
                }else if(!/^\d*\.{0,1}\d+$/.test(fieldValues.charge_time)){
                    temp.charge_time="The number is not valid.";
                }else if(formValues.charge_time.length>250){
                    temp.charge_time="The number is too large"
                }else temp.charge_time='';
            }
            if ('launch_date' in fieldValues){
                if(formValues.launch_date.length===0){
                    temp.launch_date=''
                }else if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(fieldValues.launch_date)){
                    temp.launch_date="The date is not valid.";
                }else if(formValues.launch_date.length>250){
                    temp.launch_date="The date is too large"
                }else temp.launch_date='';
            }
            if ('land_date' in fieldValues){
                if(formValues.land_date.length===0){
                    temp.land_date=''
                }else if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(fieldValues.land_date)){
                    temp.land_date="The date is not valid.";
                }else if(formValues.land_date.length>250){
                    temp.land_date="The date is too large"
                }else temp.land_date='';
            }
            
            if ('location' in fieldValues){
                if(formValues.location.length===0){
                    temp.location=''}
                else if(formValues.location.length<5 || formValues.location.length>250){
                    temp.location="The location must be more than 5 and less than 250 characters"
                }else temp.location='';
            }
            if ('lander_provider' in fieldValues){
                if(formValues.lander_provider.length===0){
                    temp.lander_provider=''}
                else if(formValues.lander_provider.length>250){
                    temp.lander_provider="The provider must be less than 250 characters"
                }else temp.lander_provider='';
            }
            if ('questions' in fieldValues){
                if(formValues.questions.length===0){
                    temp.questions=''}
                else if(formValues.questions.length<5 || formValues.questions.length>250){
                    temp.questions="The questions must be more than 5 and less than 250 characters"
                }else temp.questions='';
            }
            setErrors({
                ...temp
            });
        }
        validate(onchangeItem)
    },[onchangeItem,formValues])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertValues(initialAlertValues)
    };

    const formIsValid = (fieldValues = formValues) => {
        const isValid =
          fieldValues.name &&
          fieldValues.email &&
          fieldValues.company &&
          Object.values(errors).every((x) => x === "");
        return isValid;
      };

    const sendEmailAsync = async () => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('submit_space_charge_form');
            const updatedFormValue = {...formValues,"g-recaptcha-response":token}
            const status = await axios.post("/email/send",updatedFormValue)
            .then((res) => {
                return (res.data && res.data.status != null) ? res.data : {status: false}
            })
            .catch((error)=> {
                return {status: false}
            });
            return status;
        }else{
            setLoading(true)
        }
    }
    useEffect(()=>{
        if(executeRecaptcha){
            setLoading(false)
        }
    },[executeRecaptcha])

    useEffect(()=>{
        if(loading){
            setTimeout(() => {
                setLoadingTimeOut(true)
            }, 30000);
        }else{
            setLoadingTimeOut(false)
        }
    },[loading])

    useEffect(()=>{
        if(loadingTimeOut && loading){
            setAlertValues({
                alertOpen: true,
                alertType: "warning",
                alertMessage: "Request expired. Please refresh the page."
            })
        }
    },[loadingTimeOut,loading])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formIsValid()){
            setLoading(true)
            let info = await sendEmailAsync();
            if(info){
                setLoading(false)
            setAlertValues({
                alertOpen: true,
                alertType: (info.status) ? "success" : "warning",
                alertMessage: info.msg || "Something went wrong."
            })
            event.target.reset();
            setFormValues(initialFormValues)
            }
        }else{
            console.log('errors',errors)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOnChangeItem({ [name]: value })
        setFormValues({
          ...formValues,
          [name]: value,
        });
      };

    return (
            <form onSubmit={handleSubmit}>
            <Grid container sx={{p:2}} alignItems="center" justify="center">
                <Grid item md={4} xs={12} className='center-align'>
                <TextField id="name-input"
                    name="name"
                    label="Name"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.firstname}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["name"] && { error: true, helperText: errors["name"] })}/>
                </Grid>
                <Grid item md={4} xs={12} className='center-align'>
                <TextField id="email-input"
                    name="email"
                    label="Email"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.email}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["email"] && { error: true, helperText: errors["email"] })}/>
                </Grid>
                <Grid item md={4} xs={12} className='center-align'>
                <TextField  id="company-input"
                    name="company"
                    label="Company"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.company}
                    onChange={handleInputChange}
                    required
                    helperText=" "
                    {...(errors["company"] && { error: true, helperText: errors["company"] })}/>
                </Grid>
                <Grid item md={4} xs={12} className='center-align'>
                <TextField id="payload-input"
                    name="payload"
                    label="Payload Mass"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    value={formValues.payload}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["payload"] && { error: true, helperText: errors["payload"] })}/>
                </Grid>
                <Grid item md={4} xs={12} className='center-align'>
                <TextField id="battery-input"
                    name="battery"
                    label="Battery capacity"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Watts</InputAdornment>,
                      }}
                    value={formValues.battery}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["battery"] && { error: true, helperText: errors["battery"] })}/>
                </Grid>
                <Grid item md={4} xs={12} className='center-align'>
                <TextField id="charge_time-input"
                    name="charge_time"
                    label="Power source time to fully charge"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                      }}
                    value={formValues.charge_time}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["charge_time"] && { error: true, helperText: errors["charge_time"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="launch_date-input"
                    name="launch_date"
                    label="Anticipated Launch Date(DD/MM/YYYY)"
                    type="text"
                    placeholder='(DD/MM/YYYY)'
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.launch_date}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["launch_date"] && { error: true, helperText: errors["launch_date"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="land_date-input"
                    name="land_date"
                    label="Anticipated Landing Date(DD/MM/YYYY)"
                    type="text"
                    placeholder='(DD/MM/YYYY)'
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.land_date}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["land_date"] && { error: true, helperText: errors["land_date"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="location-input"
                    name="location"
                    label="Location of Landing"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.location}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["location"] && { error: true, helperText: errors["location"] })}/>
                </Grid>
                <Grid item md={6} xs={12} className='center-align'>
                <TextField id="provider-input"
                    name="provider"
                    label="Lander provider"
                    type="text"
                    sx={{ m: 1, width: '90%' }}
                    value={formValues.provider}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["provider"] && { error: true, helperText: errors["provider"] })}/>
                </Grid>
                <Grid item xs={12} className='center-align'>
                <TextField id="questions-input"
                    name="questions"
                    label="List questions"
                    type="text"
                    multiline rows={4}
                    sx={{ m: 1, width: '95%',}}
                    value={formValues.questions}
                    onChange={handleInputChange}
                    helperText=" "
                    {...(errors["questions"] && { error: true, helperText: errors["questions"] })}/>
                </Grid>
                <Grid item xs={12} className='center-align'>
                <Button sx={{ m: 1, width: '95%' }} variant="contained" color="primary" type="submit">
                    Submit
                </Button>
                </Grid>
                <Snackbar open={alertValues.alertOpen} autoHideDuration={20000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertValues.alertType} sx={{ width: '100%' }}>
                    {alertValues.alertMessage}
                </Alert>
                </Snackbar>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: '99'}} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </form>
    );
}

export default ChargeWithUsFormContent;