import React from 'react';
import { WideContainer} from 'components/mui/containers/PxftContainer';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer=()=> {
    const currentYear = new Date().getFullYear();
    return (
        <WideContainer className="pxft-footer-container">
            <div className='copyright-container'>
            <div className='center-align media-icons'>
                {/* <a href="https://twitter.com/stellsspace" target="_blank" rel="noopener noreferrer"><TwitterIcon/></a>
                <a href="https://facebook.com/stells.space" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a> */}
                <a href="https://www.youtube.com/@stellsspace" target="_blank" rel="noopener noreferrer"><YouTubeIcon fontSize='large'/></a> 
                <a href="https://www.instagram.com/stells.space/" target="_blank" rel="noopener noreferrer"><InstagramIcon fontSize='large'/></a>
                <a href="https://linkedin.com/company/stells" target="_blank" rel="noopener noreferrer"><LinkedInIcon fontSize='large'/></a>
            </div>
                <p className="copyright">
                    Copyright &copy; {currentYear} <strong>STELLS Space Corp.</strong> All Rights Reserved.
                    <br/>
                    <br/>Corporation License Number: 1349831-0
                    <br/>Business License Number (BN): 761735604RC0001
                    <br/>Corporate Name: Stells Space Corp. ("STELLS")
                    <br/>Governing Legislation: Canada Business Corporations Act - 2021-11-09
                </p>
            </div>

        </WideContainer>
    );
}

export default Footer;