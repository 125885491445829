import React, { useState,useEffect}  from 'react';
import BasicHeader from 'components/mui/containers/basicHeader';
import BlogsData from 'data/blogs/blogItem.json';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import BlogsArticle from 'components/blogs/blogsArticle';

const Blogs=()=> {
    const[blogs,setBlogs]=useState([])
    const blogsPerPage = 6
    const[pageCount,setPageCount] = useState(0)
    const[currpage,setCurrpage]=useState(1)
    const[blogOffset,setBlogOffset] = useState(0)

    useEffect(()=>{
        window.scrollTo(0, 0)
        let pathname = window.location.pathname
        let url = pathname.split('/')
        const setPage = (blogs)=>{
            setBlogOffset((currpage-1) * blogsPerPage);
            const endOffset = blogOffset + blogsPerPage;
            setBlogs(blogs.slice(blogOffset,endOffset));
            setPageCount(Math.ceil(blogs.length/blogsPerPage));
        }
        if(url[1]==='news-and-updates' && url[2]==='page'){
            setCurrpage(url[3])
            setPage(BlogsData)
        }
        setPage(BlogsData)
    },[blogOffset,currpage])

    const handlePageClick = (event,page) => {
        window.location.href = `/news-and-updates/page/${page}`;
    };
    return (
        <div>
            <BasicHeader title='Our News & Updates'/>
            <BlogsArticle blogs={blogs}/>
            <Stack sx={{py:6}} spacing={2} className='center-align'>
                <Pagination page={parseInt(currpage)} onChange={handlePageClick} count={pageCount} variant="outlined" shape="rounded" />
            </Stack>
        </div>
    );
}

export default Blogs;