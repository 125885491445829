import React, { useState,useEffect}  from 'react';
import BasicHeader from 'components/mui/containers/basicHeader';
import JobsData from 'data/jobs/jobItem.json';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import BlogsArticle from 'components/blogs/blogsArticle';


const Career=()=> {
    const[jobs,setjobs]=useState([])
    const jobsPerPage = 6
    const[pageCount,setPageCount] = useState(0)
    const[currpage,setCurrpage]=useState(1)
    const[jobOffset,setjobOffset] = useState(0)

    useEffect(()=>{
        window.scrollTo(0, 0)
        let pathname = window.location.pathname
        let url = pathname.split('/')
        const setPage = (jobs)=>{
            setjobOffset((currpage-1) * jobsPerPage);
            const endOffset = jobOffset + jobsPerPage;
            setjobs(jobs.slice(jobOffset,endOffset));
            setPageCount(Math.ceil(jobs.length/jobsPerPage));
        }
        if(url[1]==='career' && url[2]==='page'){
            setCurrpage(url[3])
            setPage(JobsData)
        }
        setPage(JobsData)
    },[jobOffset,currpage])

    const handlePageClick = (event,page) => {
        window.location.href = `/career/page/${page}`;
    };
    return (
        <div>
            <BasicHeader title='Career'/>
            <BlogsArticle blogs={jobs}/>
            <Stack sx={{py:6}} spacing={2} className='center-align'>
                <Pagination page={parseInt(currpage)} onChange={handlePageClick} count={pageCount} variant="outlined" shape="rounded" />
            </Stack>
        </div>
    );
}

export default Career;