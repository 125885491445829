import ChargeWithUsForm from 'components/chargeWithUs/chargeWithUsForm';
import React from 'react';
import BasicHeader from 'components/mui/containers/basicHeader';

function ChargeWithUs(props) {
    return (
        <div>
            <BasicHeader title='Charge With Us'/>
            <ChargeWithUsForm/>
        </div>
    );
}

export default ChargeWithUs;