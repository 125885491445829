import React,{useState} from 'react';
import { decode } from 'html-entities';
import { Grid,TextField,Button,} from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import SendIcon from '@mui/icons-material/Send';

function NewsLetterForm( {status, message, onValidated}) {
    const [ error, setError ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const handleFormSubmit = () => {
        setError(null);
        if(!email) {
          setError( 'Email is empty' );
          return;
        }

        if(!/\S+@\S+\.\S+/.test(email)) {
            setError( 'Please enter a valid email address' );
            return;
        }

        const isFormValidated = onValidated({ email: email });
        return email && email.indexOf("@") > -1 && isFormValidated;
      }
    const handleInputKeyEvent = ( event ) => {
        setError(null);
        if (event.keyCode === 13) {
          event.preventDefault();
          handleFormSubmit();
        }
    }
    const getMessage = (message) => {
        if ( !message ) {
         return null;
        }
        const result = message?.split('-') ?? null;
        if ( "0" !== result?.[0]?.trim() ) {
         return decode(message);
        }
        const formattedMessage = result?.[1]?.trim() ?? null;
        return formattedMessage ? decode( formattedMessage ) : null;
      }
      const containerStyle = {
        backgroundImage: `url('images/home/news-background.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
    return (
        <div>
            <WideContainer className='center-align home-article-container'>
            <div style={containerStyle}>
                <Grid className='center-align' flexDirection='column' sx={{maxWidth:'900px',marginX:'auto',paddingX:'30px'}}>
                    <Grid className='center-align title-container'>
                    <h1>Subscribe to Get Our Latest News</h1>
                    </Grid>
                    <Grid container sx={{p:2}} alignItems="center" justify="center">
                        <Grid item md={8} xs={12} className='center-align'>
                            <TextField id="email-input"
                                name="email"
                                label="Your Email"
                                type="text"
                                sx={{ m: 1, width: '95%' }}
                                required
                                onChange={(event) => setEmail(event?.target?.value ?? '')}
                                onKeyUp={(event) => handleInputKeyEvent(event)}/>
                        </Grid>
                        <Grid item md={4} xs={12} className='center-align'>
                        <Button sx={{width: '95%',height:'55px'}} 
                                variant="contained" size="large" 
                                color="primary" type="submit" endIcon={<SendIcon />}
                                onClick={handleFormSubmit}>
                            Subscribe
                        </Button>
                        </Grid>
                        <Grid item xs={12} className='center-align' sx={{py:1}}>
                            {status === "sending" && <div>Sending...</div>}
                            {status === "error" || error ? (
                            <div
                                className="newsletter-form-error"
                                dangerouslySetInnerHTML={{ __html: error || getMessage( message ) }}
                            />
                            ) : null }
                            {status === "success" && status !== "error" && !error && (
                            <div dangerouslySetInnerHTML={{ __html: decode(message) }} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            </WideContainer>
        </div>
    );
}

export default NewsLetterForm;