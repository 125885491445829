import React from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from '@mui/material';
import { NavLink } from 'react-router-dom';

const MobileMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    return (
        <div className='nav-mobile-menu'>
            <Button
                className='settingIcon'
                id="mobile-navi-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            ><MenuIcon/></Button>
            <Menu
                id="mobile-menu"
                width='100%'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'mobile-navi-button',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 35,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
            >
                <Grid container px={3} py={1} width={300}>
                    <Grid item xs={12}>
                    {/* <MenuItem divider>
                    <NavLink to={"/mpr"} onClick={handleClose} style={{color:'white'}}>MPR-1</NavLink>
                    </MenuItem>
                    <MenuItem divider>
                    <NavLink to={"/mission"} onClick={handleClose} style={{color:'white'}}>Mission</NavLink>
                    </MenuItem> */}
                    <MenuItem divider>
                    <NavLink to={"/news-and-updates"} onClick={handleClose} style={{color:'white'}}>News & Updates</NavLink>
                    </MenuItem>
                    <MenuItem divider>
                    <NavLink to={"/career"} onClick={handleClose} style={{color:'white'}}>Career</NavLink>
                    </MenuItem>
                    <MenuItem divider>
                    <NavLink to={"/contact-us"} onClick={handleClose} style={{color:'white'}}>Contact Us</NavLink>
                    </MenuItem>
                    <MenuItem>
                    <NavLink to={"/charge-with-us"} onClick={handleClose} style={{color:'white'}}>Charge with Us</NavLink>
                    </MenuItem>
                    </Grid>
                </Grid>
            </Menu>
        </div>
    )
}

export default MobileMenu;
