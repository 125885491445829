import React from 'react';
import {Slide} from '@mui/material';
import { useInView } from 'react-intersection-observer'

const Header=()=> {
    const backgroundCss = {
        backgroundImage: `url('images/home/home-background.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <div className='home-header-container' style={backgroundCss}>
            <div className='home-video'>
                <video muted playsInline autoPlay loop src='https://stells.space/saved/media/videos/background.mp4' type="video/mp4" />
            </div>
            <div className='home-title-container'>
                <div className='home-title'>
                    <div className='words-container'>
                        <Slide timeout={{enter:1200}} in={inView}  direction="down" container={containerRef.current}>
                            <div>
                                <p className='subtitle'>Power of Space</p>
                            </div>
                        </Slide>
                        <Slide timeout={{enter:1200}} in={inView}  direction="up" container={containerRef.current}>
                            <div className='title'>
                            <h1>THE FIRST ROVER </h1>
                            <h1>TO PROVIDE POWER ON THE MOON</h1>
                            </div>
                        </Slide>
                    </div>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </div>
        </div>
    );
}

export default Header;