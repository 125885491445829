import React,{useState,useEffect} from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import parse from 'html-react-parser';
import BlogsData from 'data/blogs/blogItem.json';
import JobsData from 'data/jobs/jobItem.json';

const BlogDetailsArticle = ({blog}) => {
    const [blogs,setBlogs]= useState([])
    const [prev,setPrev]= useState('');
    const [prevId,setPrevId]= useState('');
    const [next,setNext]= useState('');
    const [nextId,setNextId]= useState('');
    useEffect(()=>{
        if(blog.genre==='news-and-updates'){
            setBlogs(BlogsData)
        }else if(blog.genre==='career'){
            setBlogs(JobsData)
        }
    },[blog])
    useEffect(()=>{
        let idx = blogs.indexOf(blog);
        setPrev(idx-1);
        setPrevId(blogs[prev]?.id)
        setNext(idx+1);
        setNextId(blogs[next]?.id)
    },[prev,next,blog,nextId,blogs])

    return (
        <WideContainer>
            <div className='blog-content center-align'>
                <NarrowContainer>
                    {blog.html && parse(''+blog.html)}
                    <div className='btn-container'>
                    <Button size="small" href={prev>=0?`/${blog.genre}/details/${prevId}`:''} style={{display:blog.id === blogs.length.toString()? 'none':'flex'}}><KeyboardArrowLeft />Next</Button>
                    <Button size="small" href={next<blogs.length?`/${blog.genre}/details/${nextId}`:''} style={{marginRight:0,marginLeft:'auto',display:blog.id==='1'? 'none':'flex'}}>Prev<KeyboardArrowRight /></Button>
                    </div>
                </NarrowContainer>
            </div>
        </WideContainer>
    );
}

export default BlogDetailsArticle;