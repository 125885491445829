import React from 'react';
import BasicHeader from 'components/mui/containers/basicHeader';
import ContactForm from 'components/contactUs/contactForm';

const ContactUs= ()=> {
    return (
        <div>
            <BasicHeader title='Contact Us'/>
            <ContactForm/>
        </div>
    );
}

export default ContactUs;