import BackGraphContainer from 'components/mui/containers/backGraphContainer';
import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import {Grid,Grow,Slide} from '@mui/material';
import { useInView } from 'react-intersection-observer'
import CenterTitle from 'components/mui/containers/centerTitle';
import MegaButton from 'components/mui/buttons/megaButton';
import ArticleCardA from 'components/mui/cards/articleCardA';
import NewsLetter from './newsLetter';

const HomeArticle=()=> {
    const rows=[
        {
            title:'MPR-1',
            contents:'Mobile Power Rover (MPR-1) is a medium size rover scheduled to launch in 2024 to the South Pole of the Moon and provide power to other missions.',
            button:false,
            link:'',
            long:false,
            btnText:'',
            src:'images/home/space-1.png'
        },
        {
            title:'Team',
            contents:'STELLS is a private space company developing power technologies. \nThe team behind is a diverse group of space engineers, professors, business managers, and other professionals highly motivated to develop products that can help other space missions with power.',
            button:false,
            link:'',
            long:false,
            btnText:'',
            src:'images/home/space-2.png'
        },
        {
            title:'Mission',
            contents:'\nOur mission is to supply power to other applications on the Moon and become the main/alternative/recovery source of power. \n\nRover is capable supporting science, mining, or exploration missions on the Moon. \n\nMPR-1 is built in mind to be a reliable power source solution for others to survive the nights and safely complete missions with power source nearby.',
            button:false,
            link:'',
            long:false,
            btnText:'',
            src:'images/home/space-3.png'
        },
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerStyle_sec = {
        backgroundImage: `url('images/home/space-1.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    const containerStyle = {
        backgroundImage: `url('images/home/space-4.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
        padding:'50px 0'
    }
    return (
        <div className='home-article-container'>
            {/* <BackGraphContainer content={rows[0]}/> */}
            <WideContainer>
                <div className='home-background-column column-container center-align' style={containerStyle_sec}>
                <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={4} md={4} className='center-align'>
                            <ArticleCardA width='300px' height='500px' title={rows[0].title} content={rows[0].contents} button={rows[0].button} long={rows[0].long} btnText={rows[0].btnText} link={rows[0].link}/>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:800}} in={inView}>
                        <Grid item xs={12} sm={8} md={8} className='center-align'>
                            <iframe loading='lazy'
                                src="https://www.youtube.com/embed/i2BLlnNKUyA"
                                frameBorder="0"
                                width="100%"
                                height="150%"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="STELLS - Moon Mission Trailer"
                            />{" "}
                        </Grid>
                    </Grow>
                </NarrowContainer>
                <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer>
            <BackGraphContainer content={rows[1]}/>
            <BackGraphContainer content={rows[2]}/>
            <WideContainer>
                <div className='column-container center-align' style={containerStyle}>
                <NarrowContainer className='center-align'>
                    <Slide timeout={{enter:800}} in={inView1} direction="up" container={containerRef.current}>
                        <div className='center-align' style={{flexDirection:'column'}}>
                            <CenterTitle title='READY TO POWER WITH STELLS?' article='We’ll be on the Moon nearby to help you with power'/>
                            <div style={{padding:'30px 0'}}>
                                <a href='/charge-with-us' rel="noreferrer">
                                <MegaButton width='200px' height='60px' content='Charge with Us'/>
                                </a>
                            </div>
                        </div>
                    </Slide>
                </NarrowContainer>
                <div className='observer' ref={inViewRef1}></div>
                </div>
            </WideContainer>
            <WideContainer> 
                <Grid item pt={6} xs={12} sm={12} md={12} className='center-align' style={{flexDirection:'column'}}>
                    <CenterTitle title='Collaborators' article=''/>
                    <Grow timeout={{enter:1800}} in={inView2}>
                    <div className='center-align'>
                        <img style={{width:'80%',maxWidth:'1400px'}} src='/images/home/logos.png' alt=''/>
                    </div>
                    </Grow>
                    <div className='observer' ref={inViewRef2}></div>
                </Grid>
            </WideContainer>
            <NewsLetter/>
        </div>
    );
}

export default HomeArticle;