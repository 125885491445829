import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShareButtonDetails from 'components/mui/buttons/shareButtonDetails';

const Header = ({blog}) => {
    const blogGenre = blog.genre
    const blogLink =window.location.href
    const backgroundCss = {
        backgroundImage: `url('/images/header-background.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
        height:'320px',
    }
    return (
        <>
        <WideContainer>
        <div className='blog-header-container center-align' style={backgroundCss}>
            <NarrowContainer>
                <h1 className='title'>{blog.title}</h1>
                <div className='divider'></div>
                <Grid container>
                    <Grid item xs={10}>
                        <div className='time'>
                            <CalendarMonthIcon/>
                            <p style={{padding:'0 5px'}}>{blog.time}</p>
                            {
                                <a href={'/'+blogGenre} className='category'>{blog.category}</a>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={2} >
                        <div className='sharebtn-container'>
                            <ShareButtonDetails url={blogLink} title={blog.title}/>
                        </div>
                    </Grid>
                </Grid>
            </NarrowContainer>
        </div>
    </WideContainer>
        </>
        
    );
}

export default Header;