import {React, useState} from 'react';
import NewsLetterForm from './newsLetterForm';
import axios from 'axios.js'

function NewsLetter() {


    const [status,setStatus] = useState("")
    const [message,setMessage] = useState("Something went wrong. Please try again")

    const sendMailchimpEmail = async (data) => {

      setStatus("sending")
      const response = await axios.post("/email/mailchimp/send",data)
      .then((res) => {

        const {status,msg} = res?.data

        if(!(status)) {
          setStatus("error")
          return false
        }

        setStatus("success")
        setMessage((msg||"Saved successfully"))
        return true
      })
      .catch((e)=> {
          console.error(e)
          if(e.msg) {
            setMessage(e.msg)
          }
          setStatus("error")
          return false
      });


    }
    
    return (
      <NewsLetterForm
        status={ status }
        message={ message }
        onValidated={ formData => sendMailchimpEmail(formData)}
      />
    );
}

export default NewsLetter;