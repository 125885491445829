import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { baseTheme } from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import Layout from 'views/base/Layout.js';
import Info from 'views/base/Info.js';
import Home from 'views/home/Home.js';
import ContactUs from 'views/contactUs/ContactUs';
import Blogs from 'views/blogs/Blogs';
import Careers from 'views/career/Careers';
import CareersDetails from 'views/career/CareersDetails';
import BlogsDetails from 'views/blogs/BlogsDetails';
import ChargeWithUs from 'views/chargeWithUs/ChargeWithUs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={baseTheme}>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/charge-with-us" element={<ChargeWithUs />} />
            <Route exact path="/news-and-updates" element={<Blogs />} />
            <Route path="/news-and-updates/page/:id" element={<Blogs />} />
            <Route path="/news-and-updates/details/:id" element={<BlogsDetails />} />
            <Route exact path="/career" element={<Careers/>} />
            <Route path="/career/page/:id" element={<Careers />} />
            <Route path="/career/details/:id" element={<CareersDetails />} />
            <Route path="*" element={<Info status={404} />} />
          </Route>
        </Routes>
      </BrowserRouter>
  </ThemeProvider>
);

