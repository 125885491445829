import React, { useState,useEffect } from 'react';
import BlogDetailsArticle from 'components/blogDetails/blogDetailsArticle';
import BlogDetailsHeader from 'components/blogDetails/header';
import JobsData from 'data/jobs/jobItem.json';
import MetaTags from 'components/base/metaTags';
import 'assets/styles/blogs.css'

const CareerDetails =()=>{
    const [blog, setBlog] = useState({})
    useEffect(()=>{
        window.scrollTo(0, 0);
        let pathname = window.location.pathname
        let id = pathname.split('/')
        setBlog(JobsData.find(b=>b.id === id[3]));
    },[blog])
    const metaTags={
        webTitle:'STELLS | '+blog.title,
        webSiteName:"STELLS Space Corp.",
        webImage: '/'+blog.src,
        webDescription:blog.content,
        webUrl:window.location.href,
        locale:"en_US",
        type:"article"
    }

    return (
        <div>
            <MetaTags tag={metaTags}/>
            <BlogDetailsHeader blog={blog}/>
            <BlogDetailsArticle blog={blog}/>
        </div>
    );
}

export default CareerDetails;