import React, { useState, useEffect} from 'react';
import 'assets/styles/base/navbar.css';
import MobileMenu from './mobileMenu';
import { WideContainer} from 'components/mui/containers/PxftContainer';
import { Button, Grid } from '@mui/material';
import { NavLink } from 'react-router-dom'

const NavigationBar = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [windowWidth,setWindowWidth] = useState(window.innerWidth);
  const [smallScreen,setSmallScreen]= useState();

const handleScroll = () => {
    setScroll(window.scrollY);
};
const handleCheckWidth = () => {
    setWindowWidth(window.innerWidth)
}

  useEffect(() => {
    const navbar = document.querySelector(".nav");
    setHeaderTop(navbar.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
    window.removeEventListener("scroll", handleScroll);
    };
}, []);

  useEffect(()=>{
    window.addEventListener('resize', handleCheckWidth);
    return(() => {
        window.removeEventListener('resize', handleCheckWidth);
    })
  },[])

  useEffect(()=>{
    setSmallScreen(windowWidth<900? true:false)
  },[windowWidth])

const WindowMenu= () =>{
return(
  <Grid container className='nav-navi-contents'>
    <Grid item md={9} xs={6} className='center-align'>
      <Grid container justifyContent='left' className='center-align'>
        <Grid item pr={4} display='flex' alignItems='center' justifyContent='left' className='center-align'>
          <a href='/' rel="noreferrer">
            <img className='logoImg' src='/images/logo.png' alt='logo'/>
          </a>
        </Grid >
            <nav className="site-main-menu" style={{display:smallScreen?'none':'block'}}>
            <ul>
                {/* <li>
                    <NavLink to={"/mpr"}><span className="menu-text">MPR-1</span></NavLink>
                </li>
                <li>
                    <NavLink to={"/mission"}><span className="menu-text">Mission</span></NavLink>
                </li> */}
                <li>
                    <NavLink to={"/news-and-updates"}><span className="menu-text">News & Updates</span></NavLink>
                </li>
                <li>
                    <NavLink to={"/career"}><span className="menu-text">Career</span></NavLink>
                </li>
                <li>
                    <NavLink to={"/contact-us"}><span className="menu-text">Contact Us</span></NavLink>
                </li>
            </ul>
        </nav>
      </Grid>  
    </Grid>
    <Grid item md={3} xs={6} className='center-align' style={{justifyContent:'flex-end'}}>
    {smallScreen? 
    <MobileMenu/>:
    <div style={{display:'flex'}}>
      <a href='/charge-with-us' rel="noreferrer"><Button variant="outlined" sx={{width:200, height:50, border:'1px solid'}}>Charge With Us</Button></a>
    </div>
    }
    </Grid>
    </Grid>
);
}

    return(
      <div>
        <WideContainer className={`nav ${scroll > headerTop ? "is-sticky" : ""}`}>
          <div className='center-align'>
              {WindowMenu()}
          </div>
        </WideContainer>
      </div>
    );

}
export default NavigationBar;