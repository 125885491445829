import React from 'react';
import { NarrowContainer, WideContainer } from './PxftContainer';
import {Grid,Slide} from '@mui/material';
import { useInView } from 'react-intersection-observer'
import ArticleCardA from 'components/mui/cards/articleCardA';

const BackGraphContainer=({content})=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    const containerStyle = {
        backgroundImage: `url(${content.src})`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
    return (
        <WideContainer>
            <div className='home-background-column column-container center-align' style={containerStyle}>
            <NarrowContainer>
                <Slide timeout={{enter:800}} in={inView} direction="right" container={containerRef.current}>
                    <Grid item xs={12} sm={4} md={4} className='center-align'>
                        <ArticleCardA width='300px' height='500px' title={content.title} content={content.contents} button={content.button} long={content.long} btnText={content.btnText} link={content.link}/>
                    </Grid>
                </Slide>
            </NarrowContainer>
            <div className='observer' ref={inViewRef}></div>
            </div>
        </WideContainer>
    );
}

export default BackGraphContainer;