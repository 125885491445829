import React,{useState,useEffect} from 'react';
import {Grid,Button} from '@mui/material';

const CookieConsent =()=> {
    const [close,setClose]=useState(true)
    const currentYear = new Date().getFullYear();

    const optInCookieConsent=()=> {
        document.cookie = `consent_accept=true; expires=Thu, 31 Dec ${currentYear} 12:00:00 UTC; path=/`;
        document.cookie = `optout=false; expires=Thu, 31 Dec ${currentYear} 12:00:00 UTC; path=/`;
        setClose(true);
    }
    const getCookie=(cname)=> {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    useEffect(()=>{
        if(document.cookie){
            if(getCookie("consent_accept")==="" && (getCookie("optout") === "false" || getCookie("optout") === "")){
                setClose(false)
            }
        }else setClose(false)
    },[])

    return (
        <div className="policy-container" style={{display:close?'none':'flex'}}>
            <Grid container>
                <Grid item xs={10} className='center-align'>
                <p id="consent-text-pixfuture">We use cookies on this website because without cookies Internet would not work well.</p>
                </Grid>
                <Grid item xs={2}>
                <Button variant="contained" onClick={optInCookieConsent}>Got it</Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default CookieConsent;