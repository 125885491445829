import {
    colors,
    createTheme,
    responsiveFontSizes
} from '@mui/material';

import {softShadows} from './shadows';
import typography from "./typography";

export const baseTheme = responsiveFontSizes(createTheme({
    direction: 'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32
            }
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0)'
            }
        },
        MuiInputBase: {
            input: {
                '&::placeholder': {
                    opacity: 1,
                    color: colors.blueGrey[600]
                }
            }
        },
    },
    background:{
        paper:'#363636',
        default:'#000'
    },
    palette: {
        type: 'base',
        mode: 'dark',
        action: {
            active: '#fcb402',
        },
        primary: {
            main: '#fcb402',
            dark: '#fcb402',
        },
        secondary: {
            light: colors.yellow[600],
            main: '#fcb402',
            dark: colors.yellow[900],
        },
        info: {
            main: '#fcb402',
            dark: '#0b3599',
        },
        text: {
            primary: '#fcb402',
            secondary: '#fff'
        }
    },
    shadows: softShadows
}));
