import React,{useEffect} from 'react';
import HomeHeader from 'components/home/header';
import 'assets/styles/home.css';
import HomeArticle from 'components/home/homeArticle';
import BlogsData from 'data/blogs/blogItem.json';

const Home = ()=>{
      useEffect(()=>{
        window.scrollTo(0, 0);
      })
      return (
        <div>
          <HomeHeader/>
          <HomeArticle blogs={BlogsData}/>
        </div>
      );
}

export default Home;